import Input from "components/inputs/Input";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  handlePasswordChange,
  resendEmailVerification,
} from "services/authService";
import { passwordRegex } from "utils/globalFunc";
import { handleToast } from "utils/toasts";

export default function SecurityTab() {
  const [disabled, setDisabled] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [form, setForm] = useState({
    email: auth?.user?.email,
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const handleEmailResend = async () => {
    setDisabled(true);
    let data = await resendEmailVerification();
    if (data === false) {
      setDisabled(false);
      return;
    }
    setDisabled(false);
    handleToast("success", data?.message);
  };
  const [error, setError] = useState(false);
  const changePassword = async () => {
    if (
      !passwordRegex.test(form?.password) ||
      form?.password !== form?.confirm_password
    ) {
      setError(true);
      if (!passwordRegex.test(form?.password)) {
        handleToast(
          "error",
          "At least 6 characters, At least one lowercase letter, At least one uppercase letter, At least one digit, At least one special character"
        );
      }
      if (form?.password !== form?.confirm_password) {
        handleToast("error", "Please confirm password");
      }
    } else {
      setError(false);
      let data = await handlePasswordChange(form?.old_password, form?.password);
      if (data === false) {
        setDisabled(false);
        return;
      }
      setDisabled(false);
      handleToast("success", data?.message);
      updateForm({
        old_password: "",
        password: "",
        confirm_password: "",
      });
    }
  };
  return (
    <div className="modalProfile__sec">
      <div className="modalProfile__title mb">
        <h3>Security</h3>
      </div>
      <div className="modalProfile__sec-inner">
        <div className="modalProfile__sec-email">
          <div className="modalProfile__sec-email-title">
            <h6>Email</h6>
            <div
              className={
                "modalProfile__sec-email-title-verif " +
                (auth?.user?.emailVerified ? "" : "error")
              }
            >
              {auth?.user?.emailVerified ? (
                <p>Verified</p>
              ) : (
                <p>Not Verified</p>
              )}
            </div>
          </div>
          <Input
            type={"email"}
            placeholder={"Enter email"}
            value={form?.email}
            disabled={true}
            onChange={(e) => updateForm({ email: e.target.value })}
          />
          {!auth?.user?.emailVerified && (
            <button
              type="button"
              className="modalProfile__sec-email-btn"
              onClick={handleEmailResend}
              disabled={disabled}
            >
              <p>Resend email</p>
            </button>
          )}

          <button className="button link linked">
            <p>Save</p>
          </button>
        </div>
        <div className="modalProfile__sec-pass">
          <div className="modalProfile__sec-pass-title">
            <h6>Change password</h6>
          </div>
          <div className="modalProfile__sec-pass-inputs">
            <Input
              type={"password"}
              label={"Old password"}
              placeholder={""}
              value={form?.old_password}
              onChange={(e) => updateForm({ old_password: e.target.value })}
            />
            <Input
              type={"password"}
              label={"New password"}
              placeholder={""}
              value={form?.password}
              onChange={(e) => updateForm({ password: e.target.value })}
              error={error && !passwordRegex.test(form?.password)}
            />
            <Input
              type={"password"}
              label={"Confirm New password"}
              placeholder={""}
              value={form?.confirm_password}
              onChange={(e) => updateForm({ confirm_password: e.target.value })}
              error={error && form?.password !== form?.confirm_password}
            />
          </div>
          <button
            className="button link linked"
            disabled={disabled}
            onClick={changePassword}
          >
            <p>Save</p>
          </button>
        </div>
      </div>
    </div>
  );
}
