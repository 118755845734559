import { chevronBottom } from "components/base/SVG";
import React, { useEffect, useRef, useState } from "react";

const CustomSelect = ({
  selected = null,
  list,
  key = null,
  onChange,
  placeholder,
  error = false,
}) => {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);

  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    setCurrentList(
      !currentSelected
        ? list
        : list.filter((item) => {
            let compareKey = key ?? "value";
            return item[compareKey] !== currentSelected[compareKey];
          })
    );
  }, [list, currentSelected]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  return (
    <div
      className={`customSelect ${active ? "active" : ""} ${
        error ? "error" : ""
      }`}
      ref={wrapper}
    >
      <div className="customSelect__selected" onClick={toggleActive}>
        <span className={currentSelected ? "" : "placeholder"}>
          {currentSelected ? currentSelected.value : placeholder}
        </span>
        {chevronBottom}
      </div>
      <div className="customSelect__options">
        {currentList.map((item, index) => (
          <div
            className="customSelect__option"
            key={index}
            onClick={() => onClick(item)}
          >
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomSelect;
