import { closeIcon } from "components/base/SVG";
import Input from "components/inputs/Input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handlePasswordResetConfirm } from "services/authService";
import { handleToast } from "utils/toasts";
import { passwordRegex } from "utils/globalFunc";
import { useNavigate } from "react-router-dom";

export default function NewPasswordMod({
  setModal,
  setNewPasswordToken,
  newPasswordToken,
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    password: "",
    confirm_password: "",
  });

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !passwordRegex.test(form?.password) ||
      form?.password !== form?.confirm_password
    ) {
      setError(true);
      if (!passwordRegex.test(form?.password)) {
        handleToast(
          "error",
          "At least 6 characters, At least one lowercase letter, At least one uppercase letter, At least one digit, At least one special character"
        );
      }
      if (form?.password !== form?.confirm_password) {
        handleToast("error", "Please confirm password");
      }
      return;
    }
    setError(false);
    setDisabled(true);
    const data = await handlePasswordResetConfirm(
      newPasswordToken,
      form?.password
    );
    if (data === false) {
      setDisabled(false);
      return;
    }
    handleToast("success", data?.message); // Toast non traduit
    setDisabled(false);
    setModal(null);
    setNewPasswordToken(null);
    navigate("/");
  };

  return (
    <div className="modal__inner">
      <div
        className="modal__inner-close"
        onClick={() => {
          setModal(null);
          setNewPasswordToken(null);
          navigate("/");
        }}
      >
        {closeIcon}
      </div>
      <div className="modal__inner-title">
        <h4>Enter New Password</h4>
      </div>
      <form className="modalLogin" onSubmit={handleSubmit}>
        <div className="modalLogin__inputs">
          <Input
            type={"password"}
            label={"New password"}
            placeholder={""}
            value={form?.password}
            onChange={(e) => updateForm({ password: e.target.value })}
            error={error && !passwordRegex.test(form?.password)}
          />
          <Input
            type={"password"}
            label={"Confirm New password"}
            placeholder={""}
            value={form?.confirm_password}
            onChange={(e) => updateForm({ confirm_password: e.target.value })}
            error={error && form?.password !== form?.confirm_password}
          />
        </div>

        <button type="submit" className="button continue" disabled={disabled}>
          <span className="default"></span>
          <span className="hov"></span>
          <span className="active"></span>
          <p>Send</p>
        </button>
      </form>
    </div>
  );
}
