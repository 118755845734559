import { closeIcon } from "components/base/SVG";
import Input from "components/inputs/Input";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handlePasswordResetReq } from "services/authService";
import { handleToast } from "utils/toasts";
import { emailRegex } from "utils/globalFunc";

export default function ForgotPasswordMod({ setModal }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    email: "",
  });

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegex?.test(form?.email)) {
      setError(true);
      handleToast("error", "Please enter valid email");
      return;
    }
    setError(false);
    setDisabled(true);
    const data = await handlePasswordResetReq(form?.email);
    if (data === false) {
      setDisabled(false);
      return;
    }
    handleToast("success", data?.message); // Toast non traduit
    setDisabled(false);
    setModal(null);
  };

  return (
    <div className="modal__inner">
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-title mb">
        <h4>Reset Password</h4>
      </div>
      <form className="modalLogin" onSubmit={handleSubmit}>
        <div className="modalLogin__inputs">
          <Input
            type={"email"}
            name={"email"}
            value={form?.email}
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            label={t("loginMod.emailLabel")}
            placeholder={t("loginMod.emailPlaceholder")}
            error={error && !emailRegex?.test(form?.email)}
          />
        </div>

        <button type="submit" className="button continue" disabled={disabled}>
          <span className="default"></span>
          <span className="hov"></span>
          <span className="active"></span>
          <p>Send</p>
        </button>
      </form>
    </div>
  );
}
