import axios from "axios";
import { handleToast } from "utils/toasts";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const handleError = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    handleToast("error", error.response.data.message);
    // throw new Error(error.response.data.message);
  } else {
    handleToast("error", "An unknown error occurred.");
    // throw new Error("An unknown error occurred.");
  }
};
