import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/inputs/Input";
import { handleEmailCheck, handleUsernameCheck } from "services/authService";
import { emailRegex } from "utils/globalFunc";
import { handleToast } from "utils/toasts";

export default function Step1({ form, updateForm, steps, setCoins, coins }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleNext = async () => {
    if (!emailRegex.test(form?.email) || form?.username === "") {
      setError(true);
      if (!emailRegex.test(form?.email)) {
        handleToast("error", "Wrong email or password"); // Toast non traduit
      }
      if (form?.username === "") {
        handleToast("error", "Please enter username"); // Toast non traduit
      }
    } else {
      setError(false);
      setDisabled(true);
      handleToast("info", "Checking email and username for availability"); // Toast non traduit
      const emailCheck = await handleEmailCheck(form?.email);
      if (emailCheck === false) {
        setDisabled(false);
        return;
      }
      if (!emailCheck?.data?.available) {
        setDisabled(false);
        handleToast("error", "Email is already in use"); // Toast non traduit
        return;
      }
      const usernameCheck = await handleUsernameCheck(form?.username);
      if (usernameCheck === false) {
        setDisabled(false);
        return;
      }
      if (!usernameCheck?.data?.available) {
        setDisabled(false);
        handleToast("error", "Username is already in use"); // Toast non traduit
        return;
      }
      setDisabled(false);
      setCoins(coins + 100);
      steps.nextStep();
    }
  };

  return (
    <div className="modalStep">
      <Input
        type={"email"}
        name={"email"}
        value={form?.email}
        onChange={(e) => {
          updateForm({ email: e.target.value });
        }}
        label={t("registerModStep1.emailLabel")}
        placeholder={t("registerModStep1.emailPlaceholder")}
        error={error && !emailRegex.test(form?.email)}
      />
      <Input
        type={"text"}
        name={"username"}
        value={form?.username}
        onChange={(e) => {
          updateForm({ username: e.target.value });
        }}
        label={t("registerModStep1.usernameLabel")}
        placeholder={t("registerModStep1.usernamePlaceholder")}
        error={error && form?.username === ""}
      />
      <button
        type="button"
        className="button continue"
        disabled={disabled}
        onClick={handleNext}
      >
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{t("registerModStep1.continueButton")}</p>
      </button>
    </div>
  );
}
