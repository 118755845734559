import React, { useState } from "react";
import Input from "components/inputs/Input";
import { checkIcon } from "components/base/SVG";
import { useDispatch, useSelector } from "react-redux";
import { handleToast } from "utils/toasts";
import { updateUserInfo } from "store/redux/authSlice";
import { roobetUsernameCheck, roobetUsernameSet, roobetUsernameUnset } from "services/roobetService";

export default function RoobetLink() {
  const [error, setError] = useState(false);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [value, setValue] = useState(
      auth?.user?.roobetUsername === null ? "" : auth?.user?.roobetUsername
  );
  const [isLinked, setIsLinked] = useState(
      auth?.user?.roobetUsername === null
          ? false
          : auth?.user?.roobetUsername === ""
              ? false
              : true
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRoobetLink = async () => {
    if (value?.length < 3 || value?.length > 50) {
      setError(true);
      handleToast("error", "Username must be between 3 and 50 characters");
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    setIsDisabled(true);
    try {
      const roobetCheck = await roobetUsernameCheck(value);
      if (roobetCheck === false) {
        setIsDisabled(false);
        return;
      }
      if (!roobetCheck?.data?.available) {
        setIsDisabled(false);
        handleToast("error", "Roobet username is already in use");
        return;
      }

      setIsLoading(true);
      const roobetSetData = await roobetUsernameSet(value);
      if (roobetSetData === false) {
        setIsDisabled(false);
        setIsLoading(false);
        return;
      }

      handleToast("success", roobetSetData?.message);
      dispatch(updateUserInfo(roobetSetData?.data));
      setIsLoading(false);
      setIsLinked(true);
    } catch (error) {
      console.error("Error linking Roobet username:", error);
      handleToast("error", "An unexpected error occurred.");
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  const handleRoobetUnlink = async () => {

    setIsDisabled(true);
    setIsLoading(true);
    try {
      const roobetUnsetData = await roobetUsernameUnset();
      if (roobetUnsetData === false) {
        setIsDisabled(false);
        setIsLoading(false);
        return;
      }

      handleToast("success", roobetUnsetData?.message || "Roobet username unlinked successfully.");
      dispatch(updateUserInfo(roobetUnsetData?.data));
      setIsLoading(false);
      setIsLinked(false);
      setValue("");
    } catch (error) {
      console.error("Error unlinking Roobet username:", error);
      handleToast("error", "An unexpected error occurred.");
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  return (
      <div className="modalProfile__prof-content-item">
        <div className="modalProfile__prof-content-item-title">
          <p>Roobet Username</p>
        </div>
        <div className="modalProfile__prof-content-item-row">
          <div className="modalProfile__prof-content-item-row-inner">
            <div className="modalProfile__prof-content-item-input">
              <Input
                  type={"text"}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  placeholder={""}
                  disabled={isLinked}
                  error={(error && value?.length < 3) || value?.length > 50}
              />
            </div>
            <button
                type="button"
                disabled={isDisabled}
                className={"button link " + (isLinked || isLoading ? "unlink" : "")}
                onClick={isLinked ? handleRoobetUnlink : handleRoobetLink}
            >
              {isLoading ? (
                  checkIcon // Vous pouvez remplacer ceci par un spinner pour une meilleure UX
              ) : isLinked ? (
                  <div className="unlink-button-content">
                    <p>Unlink</p>
                  </div>
              ) : (
                  <p>Link</p>
              )}
            </button>
          </div>

          {/* <button type="button" className="modalProfile__prof-content-item-btn">
            <p>Change account</p>
          </button> */}
        </div>
      </div>
  );
}
