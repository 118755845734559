import React from "react";

export default function Loader() {
  return (
    // <main>
    <div className="loader__outer fixed">
      <div className="loader "></div>
    </div>
    // </main>
  );
}
