import { crownIcon } from "components/base/SVG";
import React from "react";
import rolex2 from "assets/images/store/rolex2.png";
import pc from "assets/images/store/pc.png";
import macbook from "assets/images/store/macbook.png";
import iphone2 from "assets/images/store/iphone2.png";
import airpods from "assets/images/store/airpods.png";
import tip from "assets/images/store/tip.png";
import steam from "assets/images/store/steam.png";
import amazon from "assets/images/store/amazon.png";
import circle from "assets/images/store/circle.png";
import { formatNumberComma } from "utils/globalFunc";
import shardIcon from "assets/icons/shardIcon.png";

export default function PrizeCard({ itemData, onClick }) {
  return (
    <div
      className={
        "store__prize-item " +
        (itemData?.shopRarity?.toLowerCase() === "legendary" ? "legend" : "") +
        (itemData?.shopRarity?.toLowerCase() === "epic" ? "epic" : "")
      }
    >
      <div className="store__prize-item-inner">
        {itemData?.shopRarity?.toLowerCase() === "legendary" && (
          <>
            <div className="store__prize-item-bg"></div>
            <div className="store__prize-item-bg2"></div>
            <div className="store__prize-item-bg3"></div>
            <div className="store__prize-item-bg4"></div>
            <div className="store__prize-item-bg5"></div>
            <div className="store__prize-item-bg6"></div>
            <div className="store__prize-item-bg7"></div>
            <div className="store__prize-item-bg8"></div>
          </>
        )}
        {itemData?.shopRarity?.toLowerCase() === "epic" && (
          <>
            <div className="store__prize-item-backg"></div>
            <div className="store__prize-item-backg2"></div>
            <div className="store__prize-item-backg3"></div>
          </>
        )}
        <div className="store__prize-item-title">
          {itemData?.shopRarity === "LEGENDARY" && crownIcon}
          <h6>{itemData?.title}</h6>
        </div>
        <div className="store__prize-item-image-outer">
          <div className="store__prize-item-image">
            {itemData?.id === "rolex-datejust-41" && (
              <img src={rolex2} alt="image" />
            )}
            {itemData?.id === "gamingpc-4080ti" && <img src={pc} alt="image" />}
            {itemData?.id === "macbook-pro-m3" && (
              <img src={macbook} alt="image" />
            )}
            {itemData?.id === "iphone-16pro" && (
              <img src={iphone2} alt="image" />
            )}
            {itemData?.id === "airpodspro2" && (
              <img src={airpods} alt="image" />
            )}
            {itemData?.id?.includes("tip") && <img src={tip} alt="image" />}

            {itemData?.id === "steam100giftcard" && (
              <img src={steam} alt="image" />
            )}
            {itemData?.id === "amazon100giftcard" && (
              <img src={amazon} alt="image" />
            )}
            {/* <img src={itemData?.imageURL} alt="image" /> */}
          </div>
          {itemData?.id?.includes("tip") && (
            <div className="store__prize-item-image-circle">
              <img src={circle} alt="circle" />
            </div>
          )}
        </div>
      </div>

      <button className="button buy" onClick={onClick}>
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{formatNumberComma(itemData?.price?.toString())}</p>
        <img src={shardIcon} alt="shardIcon" />
      </button>
    </div>
  );
}
