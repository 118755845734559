import React, { useEffect, useState } from "react";
import calendarGreen from "assets/icons/calendarGreen.svg";
import calendarIcon from "assets/icons/calendarIcon.svg";
import emailGreen from "assets/icons/emailGreen.svg";
import emailIcon from "assets/icons/emailIcon.svg";
import kickGreen from "assets/icons/kickGreen.svg";
import kickIcon from "assets/icons/kickIcon.svg";
import lockGreen from "assets/icons/lockGreen.svg";
import lockIcon from "assets/icons/lockIcon.svg";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import logo from "assets/images/logo.svg";
import shards from "assets/images/shards/shards.png"
import { useTranslation, Trans } from "react-i18next";

import { closeIcon, fossCasIcon, navCloseIcon } from "components/base/SVG";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPreEmail } from "store/redux/commonSlice";

export default function RegisterMod({ setModal }) {
  const { t } = useTranslation();
  const [coins, setCoins] = useState(100);
  const { preEmail } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const steps = useSteps();
  const [progress, setProgress] = useState(10);
  const [form, setForm] = useState({
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    bday: "",
    bmonth: "",
    byear: "",
    kick_username: "",
  });
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const commonProps = {
    form,
    updateForm,
    coins,
    setCoins,
  };
  useEffect(() => {
    if (steps?.steps[0]?.active) {
      setProgress(10);
    } else if (steps?.steps[1]?.active) {
      setProgress(25);
    } else if (steps?.steps[2]?.active) {
      setProgress(40);
    } else if (steps?.steps[3]?.active) {
      setProgress(55);
    } else if (steps?.steps[4]?.active) {
      setProgress(100);
    }
  }, [steps?.steps]);
  useEffect(() => {
    setForm({
      email: preEmail,
      username: "",
      password: "",
      confirm_password: "",
      bday: "",
      bmonth: "",
      byear: "",
      kick_username: "",
    });
    dispatch(setPreEmail(""));
  }, []);
  return (
    <div className="modal__inner">
      {/* <div className="modal__head">
        <Link
          to={"/"}
          className="modal__head-logo"
          onClick={() => {
            setModal(false);
          }}
        >
          <img src={logo} alt="" />
        </Link>
        <button
          type="buton"
          className="modal__head-close"
          onClick={() => {
            setModal(false);
          }}
        >
          {navCloseIcon}
        </button>
      </div> */}
      <div
        className="modal__inner-close"
        onClick={() => {
          setModal(null);
        }}
      >
        {closeIcon}
      </div>
      <div className="modal__inner-title">
        <h4>
          <Trans i18nKey="registerModStep1.register">
          </Trans>
        </h4>
      </div>
      <div className="modalProgress">
        <div className="modalProgress__line">
          <span style={{ width: `${progress}%` }}></span>
        </div>
        <div className="modalProgress__steps">
          <div
            className={
              "modalProgress__step " +
              (steps.steps[0].active || steps.steps[0].passed ? "active" : "")
            }
          >
            <span></span>
            <img src={emailIcon} alt="" />
            <img src={emailGreen} className="green" alt="" />
          </div>
          <div
            className={
              "modalProgress__step " +
              (steps.steps[1].active || steps.steps[1].passed ? "active" : "")
            }
          >
            <span></span>

            <img src={lockIcon} alt="" />
            <img src={lockGreen} className="green" alt="" />
          </div>
          <div
            className={
              "modalProgress__step " +
              (steps.steps[2].active || steps.steps[2].passed ? "active" : "")
            }
          >
            <span></span>

            <img src={calendarIcon} alt="" />
            <img src={calendarGreen} className="green" alt="" />
          </div>
          <div
            className={
              "modalProgress__step " +
              (steps.steps[3].active || steps.steps[3].passed ? "active" : "")
            }
          >
            <span></span>

            <img src={kickIcon} alt="" />
            <img src={kickGreen} className="green" alt="" />
          </div>
        </div>
        <div
          className={
            "modalProgress__coin " +
            (steps.steps[4].active || steps.steps[4].passed ? "active" : "")
          }
        >
          <span></span>
          <p>{coins}</p>
          <img
            src={shards}
            alt="Shards Icon"
            style={{
              zIndex: 10,
              position: "relative",
              paddingLeft: 10,
              marginRight: -20
            }}
          />
        </div>
      </div>
      <div className="modalSteps">
        {steps.steps[0].active && <Step1 steps={steps} {...commonProps} />}
        {steps.steps[1].active && <Step2 steps={steps} {...commonProps} />}
        {steps.steps[2].active && <Step3 steps={steps} {...commonProps} />}
        {steps.steps[3].active && <Step4 steps={steps} {...commonProps} />}
        {steps.steps[4].active && (
          <Step5 steps={steps} {...commonProps} setModal={setModal} />
        )}
      </div>
    </div>
  );
}
const useSteps = () => {
  const [active, setActive] = useState(0);
  const [steps, setSteps] = useState([
    {
      title: "email",
      active: true,
      passed: false,
    },
    {
      title: "password",
      active: false,
      passed: false,
    },
    {
      title: "birthday",
      active: false,
      passed: false,
    },

    {
      title: "kick",
      active: false,
      passed: false,
    },
    {
      title: "final",
      active: false,
      passed: false,
    },
  ]);
  const updatedSteps = (active) =>
    setSteps((steps) =>
      steps.map((item, index) => ({
        ...item,
        active: active === index,
      }))
    );
  const nextStep = () => {
    setActive(active + 1);
    updatedSteps(active + 1);
    setPassed(true);
  };
  const prevStep = () => {
    setActive(active - 1);
    updatedSteps(active - 1);
  };
  const goToStep = (index) => {
    setActive(index);
    updatedSteps(index);
  };
  const setPassed = (isPassed = true) => {
    setSteps((steps) =>
      steps.map((item, index) =>
        index === active
          ? {
            ...item,
            passed: isPassed,
          }
          : item
      )
    );
  };
  return {
    steps: steps,
    nextStep: nextStep,
    prevStep: prevStep,
    goToStep: goToStep,
    setPassed: setPassed,
  };
};
