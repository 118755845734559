import { handleError, instance } from "./api";

export const roobetUsernameCheck = async (username) => {
  try {
    const result = await instance.post("/roobet/username/check", {
      username,
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const roobetUsernameSet = async (username) => {
  try {
    const result = await instance.post(
      "/roobet/username/set",
      {
        username,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const roobetUsernameUnset = async () => {
  try {
    const result = await instance.delete(
        "/roobet/username/unset",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};