import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "components/base/Header";
import Footer from "components/base/Footer";
import { AnimatePresence } from "framer-motion";
import Modal from "components/base/Modal";
import RegisterMod from "components/modals/register/RegisterMod";
import LoginMod from "components/modals/LoginMod";
import ProfileMod from "components/modals/profile/ProfileMod";
import RegisterRoobMod from "components/modals/RegisterRoobMod";
import RegisterPackMod from "components/modals/RegisterPackMod";
import Loader from "components/base/Loader";
import StorePage from "pages/Store/StorePage";
import BonusModal from "components/modals/BonusModal";
import BonusSuccessModal from "components/modals/BonusSuccessModal";
import ReactGA from "react-ga4";
import { WebSocketProvider } from "./services/websocket/WebSocketContext";
import NotificationModal from "components/modals/NotificationModal";
import ForgotPasswordMod from "components/modals/ForgotPasswordMod";
import NewPasswordMod from "components/modals/NewPasswordMod";
import VipRulesMod from "./components/modals/vip/VipRulesMod";

ReactGA.initialize("G-4CS2T7ZECN");

const HomePage = lazy(() => import("pages/Home/HomePage"));
const JackpotPage = lazy(() => import("pages/Jackpot/JackpotPage"));
const TermsOfService = lazy(() => import("pages/Legals/TermsOfService"));
const LeaderboardPack = lazy(() =>
  import("pages/LeaderboardPack/LeaderboardPack")
);
const GuessBalance = lazy(() => import("pages/GuessBalance/GuessBalance"));
const PrivacyPolicy = lazy(() => import("pages/Legals/PrivacyPolicy"));
const EmailConfirm = lazy(() => import("pages/EmailConfirm/EmailConfirm"));
const StatsPage = lazy(() => import("pages/Stats/StatsPage"));
const ProfilePage = lazy(() => import("pages/Profile/ProfilePage"));
const VipPage = lazy(() => import("pages/Vip/VipPage"));
const PasswordReset = lazy(() => import("pages/PasswordReset/PasswordReset"));
const DiscordCallback = lazy(() =>
  import("pages/DiscordCallback/DiscordCallback")
);

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
};

function App() {
  const [modal, setModal] = useState(null);
  const [newPasswordToken, setNewPasswordToken] = useState(null);
  const location = useLocation();
  const [menu, setMenu] = useState(false);
  const [bonusData, setBonusData] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const savedToken = localStorage.getItem("token");
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  useEffect(() => {
    if (modal || menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [modal, menu]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setMenu(false);
    setModal(false);
  }, [location]);
  return (
    <>
      <WebSocketProvider token={token}>
        <Suspense fallback={<Loader />}>
          <Header
            setModal={setModal}
            setBonusData={setBonusData}
            modal={modal}
            setMenu={setMenu}
            menu={menu}
          />
          <Analytics />
          <Routes>
            <Route
              path="*"
              element={
                <HomePage
                  setModal={setModal}
                  modal={modal}
                  setBonusData={setBonusData}
                />
              }
            />
            <Route
              path="/jackpot"
              element={<JackpotPage setModal={setModal} modal={modal} />}
            />
            <Route
              path="/profile"
              element={<ProfilePage setModal={setModal} modal={modal} />}
            />
            <Route path="/vip" element={<VipPage setModal={setModal} />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/guess-balance"
              element={<GuessBalance setModal={setModal} />}
            />
            <Route
              path="/leaderboard-packdraw"
              element={<LeaderboardPack setModal={setModal} modal={modal} />}
            />
            <Route
              path="/shop"
              element={<StorePage setModal={setModal} modal={modal} />}
            />
            <Route path="/email-confirm" element={<EmailConfirm />} />
            <Route
              path="/password-reset"
              element={
                <PasswordReset
                  setModal={setModal}
                  setNewPasswordToken={setNewPasswordToken}
                />
              }
            />
            <Route path="/discord" element={<DiscordCallback />} />
          </Routes>
          <Footer />
        </Suspense>
        <AnimatePresence>
          {modal === "register-roobet" && (
            <Modal addClass="addClass" setModal={setModal}>
              <RegisterRoobMod setModal={setModal} />
            </Modal>
          )}
          {modal === "register-packdraw" && (
            <Modal addClass="addClass" setModal={setModal}>
              <RegisterPackMod setModal={setModal} />
            </Modal>
          )}
          {modal === "register" && (
            <Modal addClass="addClass newHead" setModal={setModal}>
              <RegisterMod setModal={setModal} />
            </Modal>
          )}
          {modal === "login" && (
            <Modal addClass="addClass newHead" setModal={setModal}>
              <LoginMod setModal={setModal} />
            </Modal>
          )}
          {modal === "forgot-password" && (
            <Modal addClass="addClass newHead" setModal={setModal}>
              <ForgotPasswordMod setModal={setModal} />
            </Modal>
          )}
          {modal === "new-password" && (
            <Modal addClass="addClass newHead" setModal={setModal}>
              <NewPasswordMod
                setModal={setModal}
                newPasswordToken={newPasswordToken}
                setNewPasswordToken={setNewPasswordToken}
              />
            </Modal>
          )}
          {modal === "profile" && (
            <Modal addClass="addClass" setModal={setModal}>
              <ProfileMod setModal={setModal} />
            </Modal>
          )}
          {modal === "bonus" && (
            <Modal addClass="addClass" setModal={setModal}>
              <BonusModal
                setModal={setModal}
                bonusData={bonusData}
                setBonusData={setBonusData}
              />
            </Modal>
          )}
          {modal === "bonusSuccess" && (
            <Modal addClass="addClass" setModal={setModal}>
              <BonusSuccessModal
                setModal={setModal}
                setBonusData={setBonusData}
              />
            </Modal>
          )}
          {modal === "vip-rules" && (
              <Modal addClass="addClass" setModal={setModal}>
                <VipRulesMod setModal={setModal} />
              </Modal>
          )}
          {modal === "notif" && <NotificationModal />}
        </AnimatePresence>
      </WebSocketProvider>
    </>
  );
}

export default App;
