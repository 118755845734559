export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,15}$/;
export const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W_]{6,}$/;

export const formatNumber = (n) => {
  if (!n) return "0";
  return n.toString().replace(/\D/g, "");
};

export const formatNumberComma = (n) => {
  if (!n) return "0";
  return n
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatNumberDot = (n) => {
  if (!n) return "0";
  return n
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const formatCurrency = (value) => {
  if (!value || value === "0") return "";

  let inputVal = value.toString();

  if (inputVal.includes(".")) {
    const [leftSide, rightSide] = inputVal.split(".");
    const formattedLeft = formatNumberComma(leftSide);
    const formattedRight = rightSide.substring(0, 2); // Limit to 2 decimal digits
    return `${formattedLeft}.${formattedRight}`;
  } else {
    return formatNumberComma(inputVal);
  }
};

export const formatBalance = (value) => {
  if (!value || value === "0") return "";

  let inputVal = value.toString();

  if (inputVal.includes(".")) {
    const [leftSide, rightSide] = inputVal.split(".");
    const formattedLeft = formatNumber(leftSide);
    const formattedRight = rightSide.substring(0, 2); // Limit to 2 decimal digits
    return `${formattedLeft}.${formattedRight}`;
  } else {
    return formatNumber(inputVal);
  }
};

export const readFile = ({ file, onStart, onLoad, onLoadend }) => {
  if (file) {
    const reader = new FileReader();
    onStart?.(); // Call onStart if defined
    reader.onload = (e) => onLoad?.(e.target.result);
    reader.onloadend = () => onLoadend?.();
    reader.readAsDataURL(file);
  }
};

export const generateArrayOfYears = (number) => {
  const max = new Date().getFullYear();
  const min = max - number;
  const years = [];
  for (let i = max; i >= min; i--) {
    years.push({ id: `${max - i + 1}`, value: `${i}` });
  }
  return years;
};
