import { closeIcon } from "components/base/SVG";
import React, { useState } from "react";
import VipTab from "./components/VipTab";
import ProfileTab from "./components/ProfileTab";
import SecurityTab from "./components/SecurityTab";
import { AnimatePresence } from "framer-motion";

export default function ProfileMod({ setModal }) {
  const [tab, setTab] = useState("vip");
  return (
    <div className="modalProfile">
      <div className="modalProfile__btns">
        <button
          type="button"
          className={
            "modalProfile__btn " +
            (tab === "vip" ? "active" : "") +
            (tab === "profile" ? "remove" : "")
          }
          onClick={() => setTab("vip")}
        >
          <p>VIP</p>
        </button>
        <button
          type="button"
          className={"modalProfile__btn " + (tab === "profile" ? "active" : "")}
          onClick={() => setTab("profile")}
        >
          <p>Profile</p>
        </button>
        <button
          type="button"
          className={
            "modalProfile__btn " +
            (tab === "security" ? "active" : "") +
            (tab === "profile" ? "remove" : "")
          }
          onClick={() => setTab("security")}
        >
          <p>Security</p>
        </button>
      </div>
      <div className="modalProfile__inner">
        <button
          type="button"
          className="modalProfile__inner-close"
          onClick={() => setModal(null)}
        >
          {closeIcon}
        </button>
        <AnimatePresence>
          {tab === "vip" && <VipTab />}
          {tab === "profile" && <ProfileTab />}
          {tab === "security" && <SecurityTab />}
        </AnimatePresence>
      </div>
    </div>
  );
}
