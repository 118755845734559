import { handleError, instance } from "./api";

export const kickUsernameCheck = async (username) => {
  try {
    const result = await instance.post("/kick/username/check", {
      username,
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const kickUsernameSet = async (username) => {
  try {
    const result = await instance.post(
      "/kick/username/set",
      {
        username,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const kickUsernameUnset = async () => {
  try {
    const result = await instance.delete(
        "/kick/username/unset",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};