import { closeIcon, playIcon } from "components/base/SVG";
import React from "react";
import packLabel from "assets/images/howto/packLabel.png";
import packStep1 from "assets/images/howto/packStep1.png";
import packStep2 from "assets/images/howto/packStep2.png";
import packStep3 from "assets/images/howto/packStep3.png";
import regPackBtn from "assets/images/howto/regPackBtn.png";

export default function RegisterPackMod({ setModal }) {
  return (
    <div className="modalPack">
      <div className="modalPack__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalPack__inner">
        <div className="modalPack__inner-title">
          <p>How to Register on </p>
          <img src={packLabel} alt="" />
          <p>?</p>
        </div>
        <div className="modalPack__step1">
          <div className="modalPack__step1-bg"></div>
          <div className="modalPack__number">
            <p>1</p>
          </div>
          <div className="modalPack__step1-content">
            <h5>VISIT</h5>
            <h6>roobet.com/foss</h6>
          </div>
          <div className="modalPack__step1-image">
            <img src={packStep1} alt="" />
          </div>
        </div>
        <div className="modalPack__step2">
          <div className="modalPack__step2-bg"></div>
          <div className="modalPack__step2-bg2"></div>
          <div className="modalPack__number">
            <p>2</p>
          </div>
          <div className="modalPack__step2-content">
            <p>CLICK on</p>
            <img src={regPackBtn} alt="" />
          </div>
          <div className="modalPack__step2-image">
            <img src={packStep2} alt="" />
          </div>
        </div>
        <div className="modalPack__step3">
          <div className="modalPack__step3-bg"></div>
          <div className="modalPack__step3-bg2"></div>
          <div className="modalPack__step3-bg3"></div>
          <div className="modalPack__number">
            <p>3</p>
          </div>
          <div className="modalPack__step3-content">
            <p>
              Fill in your <br /> Details
            </p>
          </div>
          <div className="modalPack__step3-image">
            <img src={packStep3} alt="" />
          </div>
        </div>
        <button type="button" className="button play">
          <span className="default"></span>
          <span className="hov"></span>
          <span className="active"></span>
          <p>PLAY NOW</p>
          {playIcon}
        </button>
      </div>
    </div>
  );
}
