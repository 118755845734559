import { handleError, instance } from "./api";

export const handleRegister = async (
  username,
  email,
  password,
  birthday,
  kickUsername
) => {
  try {
    const result = await instance.post("/auth/register", {
      username,
      email,
      password,
      birthday,
      kickUsername,
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handleLogin = async (email, password) => {
  try {
    const result = await instance.post("/auth/login", { email, password });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    // handleError(error);
    return false;
  }
};

export const handleEmailCheck = async (email) => {
  try {
    const result = await instance.post("/auth/email/check", { email });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handleUsernameCheck = async (username) => {
  try {
    const result = await instance.post("/auth/username/check", { username });

    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handleKickUsernameCheck = async (username) => {
  try {
    const result = await instance.post("/kick/username/check", { username });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const resendEmailVerification = async () => {
  try {
    const result = await instance.post(
      "/auth/email/verification",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handleEmailConfirm = async (code) => {
  try {
    const result = await instance.post("/auth/email/confirm", { code });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handleEmailChange = async (currentPassword, newEmail) => {
  try {
    const result = await instance.post(
      "/auth/email/change",
      {
        currentPassword,
        newEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handlePasswordChange = async (oldPassword, newPassword) => {
  try {
    const result = await instance.post(
      "/auth/password/change",
      {
        oldPassword,
        newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handlePasswordResetReq = async (email) => {
  try {
    const result = await instance.post("/auth/password/reset/request", {
      email,
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const handlePasswordResetConfirm = async (token, newPassword) => {
  try {
    const result = await instance.post("/auth/password/reset/confirm", {
      token,
      newPassword,
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};
