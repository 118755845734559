import React from "react";
import { Link } from "react-router-dom";
import footerLogo from "assets/images/footer-logo.svg";
import LanguageSelect from "components/inputs/LanguageSelect";
import { useTranslation, Trans } from "react-i18next";
import { languageList } from "utils/constantLists";
import { discordIcon, instagramIcon, telegramIcon, twitterIcon } from "./SVG";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer__top">
        <div className="auto__container">
          <div className="footer__top-inner">
            <div className="footer__top-row">
              <Link to={"/"} className="footer__top-logo">
                <img src={footerLogo} alt={t("footerLogoAlt", "Footer Logo")} />
              </Link>
              <a
                href="https://x.com/RoobetReloads"
                target="_blank"
                type="button"
                className="footer__top-btn"
              >
                {telegramIcon}
                <Trans i18nKey="contactUsFooter">
                  <p>Contact us</p>
                </Trans>
              </a>
            </div>
            <div className="footer__top-socials">
              <a
                href="https://twitter.com/RoobetReloads"
                aria-label={t("twitterLinkAlt", "Twitter link")}
                target="_blank"
                className="footer__top-social"
              >
                {twitterIcon}
              </a>
              <a
                href="https://www.instagram.com/fosscasino"
                aria-label={t("instagramLinkAlt", "Instagram link")}
                target="_blank"
                className="footer__top-social"
              >
                {instagramIcon}
              </a>
              <a
                href="https://discord.com/invite/GfM6P9k46J"
                aria-label={t("discordLinkAlt", "Discord link")}
                target="_blank"
                className="footer__top-social"
              >
                {discordIcon}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__links">
        <div className="auto__container">
          <div className="footer__links-inner">
            <div className="footer__links-col">
              <h6>{t("legals", "Legals")}</h6>
              <Link to="/terms-of-service" className="footer__links-col-link">
                {t("termsOfService", "Terms of Service")}
              </Link>
              <Link to="/privacy-policy" className="footer__links-col-link">
                {t("privacyPolicy", "Privacy Policy")}
              </Link>
              <a
                href="https://www.gambleaware.org/"
                className="footer__links-col-link"
                target="_blank"
              >
                {t("responsibleGambling", "Responsible Gambling")}
              </a>
            </div>
            <div className="footer__links-col">
              <h6>{t("languages", "Languages")}</h6>
              <LanguageSelect
                list={languageList}
                placeholder={t("selectLanguage", "Select Language")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__note">
        <div className="auto__container">
          <div className="footer__note-inner">
            <p>
              <span>{t("responsibleGambling", "Responsible Gambling")}</span>{" "}
              {t(
                "footerDisclaimer",
                "We are not responsible for any losses on casino sites linked to any of our bonus offers. The player is responsible for the amount they are willing and able to play with. Do not gamble with money you cannot afford to lose. Do not chase your losses. Players have a responsibility to check the gambling laws in their country or jurisdiction, and they should do so before gambling at any online gambling site."
              )}
            </p>
            <p>
              <span>{t("pleaseNote", "Please note")}:</span>{" "}
              {t(
                "footerNote",
                "Offers on our site are subject to change or cancellation. We always recommend that the player review the terms and conditions and verify the bonus directly on the casino/betting company website."
              )}
              <br />
              {t(
                "footerAgeDisclaimer",
                "18 years and older, new customers only, T&C Apply, play responsibly"
              )}{" "}
              <a
                href="https://www.gambleaware.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("gambleAware", "www.begambleaware.org")}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="footer__copy">
        <div className="auto__container">
          <div className="footer__copy-inner">
            <p>
              {t(
                "copyright",
                "2024 Copyrights © roobetreloads.com All rights reserved."
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
