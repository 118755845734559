import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/inputs/Input";
import { passwordRegex } from "utils/globalFunc";
import { handleToast } from "utils/toasts";

export default function Step2({ form, updateForm, steps, setCoins, coins }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleNext = () => {
    if (
      !passwordRegex.test(form?.password) ||
      form?.password !== form?.confirm_password
    ) {
      setError(true);
      if (!passwordRegex.test(form?.password)) {
        handleToast(
          "error",
          "At least 6 characters, At least one lowercase letter, At least one uppercase letter, At least one digit, At least one special character" // Toast non traduit
        );
      }
      if (form?.password !== form?.confirm_password) {
        handleToast("error", "Please confirm password"); // Toast non traduit
      }
    } else {
      setError(false);
      setCoins(coins + 100);
      steps.nextStep();
    }
  };

  return (
    <div className="modalStep">
      <Input
        type={"password"}
        name={"password"}
        value={form?.password}
        onChange={(e) => {
          updateForm({ password: e.target.value });
        }}
        label={t("registerModStep2.passwordLabel")}
        placeholder={t("registerModStep2.passwordPlaceholder")}
        error={error && !passwordRegex.test(form?.password)}
      />
      <Input
        type={"password"}
        name={"confirm-password"}
        value={form?.confirm_password}
        onChange={(e) => {
          updateForm({ confirm_password: e.target.value });
        }}
        label={t("registerModStep2.confirmPasswordLabel")}
        placeholder={t("registerModStep2.confirmPasswordPlaceholder")}
        error={error && form?.password !== form?.confirm_password}
      />
      <button
        type="button"
        className="button continue"
        onClick={handleNext}
        disabled={disabled}
      >
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{t("registerModStep2.continueButton")}</p>
      </button>
    </div>
  );
}
