import { handleError, instance } from "./api";

export const getBonusData = async () => {
  try {
    const result = await instance.get("/bonus");
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const claimBonusHandler = async (id, isLogged) => {
  try {
    const result = await instance.post(
      "/bonus/claim",
      { id },
      isLogged && {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};
