import { handleError, instance } from "./api";

const DISCORD_CLIENT_ID = "1297915042427179150";
const REDIRECT_URI = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/discord`);
const DISCORD_SCOPE = "identify+email";

export const getDiscordOAuthLink = () => {
  return `https://discord.com/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&scope=${DISCORD_SCOPE}`;
};

export const handleDiscordCallback = async (code) => {
  try {
    const result = await instance.post(
      "/discord/callback",
      { code },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};
