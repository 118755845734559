import { closeIcon } from "components/base/SVG";
import React from "react";
import { useTranslation, Trans } from "react-i18next";
import roobStep1 from "assets/images/howto/roobStep1.png";
import roobStep2 from "assets/images/howto/roobStep2.png";
import roobStep3 from "assets/images/howto/roobStep3.png";
import roobetLabel from "assets/images/howto/roobetLabel.svg";
import regRoobBtn from "assets/images/howto/regRoobBtn.png";
import roobBtn from "assets/images/howto/roobBtn.png";

export default function RegisterRoobMod({ setModal }) {
  const { t } = useTranslation();

  return (
    <div className="modalRoob">
      <div className="modalRoob__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalRoob__inner">

        <div className="modalRoob__inner-title">
          <p><Trans i18nKey="registerRoobMod.title"></Trans></p>
          <img src={roobetLabel} alt="" />
          <p>?</p>
        </div>

        <div className="modalRoob__step1">
          <div className="modalRoob__step1-bg"></div>
          <div className="modalRoob__step1-bg2"></div>
          <div className="modalRoob__number">
            <p>1</p>
          </div>
          <div className="modalRoob__step1-content">
            <h5>{t('registerRoobMod.step1.title')}</h5>
            <h6>{t('registerRoobMod.step1.subtitle')}</h6>
          </div>
          <div className="modalRoob__step1-image">
            <img src={roobStep1} alt={t('registerRoobMod.step1ImageAlt')} />
          </div>
        </div>
        <div className="modalRoob__step2">
          <div className="modalRoob__step2-bg"></div>
          <div className="modalRoob__step2-bg2"></div>
          <div className="modalRoob__step2-bg3"></div>
          <div className="modalRoob__number">
            <p>2</p>
          </div>
          <div className="modalRoob__step2-content">
            <p>{t('registerRoobMod.step2.description')}</p>
            <img src={regRoobBtn} alt={t('registerRoobMod.regRoobBtnAlt')} />
          </div>
          <div className="modalRoob__step2-image">
            <img src={roobStep2} alt={t('registerRoobMod.step2ImageAlt')} />
          </div>
        </div>
        <div className="modalRoob__step3">
          <div className="modalRoob__step3-bg"></div>
          <div className="modalRoob__step3-bg2"></div>
          <div className="modalRoob__step3-bg3"></div>
          <div className="modalRoob__number">
            <p>3</p>
          </div>
          <div className="modalRoob__step3-content">
            <p>
              <Trans i18nKey="registerModStep1s.register">
                Register
              </Trans>
            </p>
          </div>
          <div className="modalRoob__step3-image">
            <img src={roobStep3} alt={t('registerRoobMod.step3ImageAlt')} />
          </div>
        </div>
        <button type="button" className="modalRoob__btn">
          <img src={roobBtn} alt={t('registerRoobMod.roobBtnAlt')} />
        </button>
      </div>
    </div>
  );
}
