import { chevronBottom, packdrowIcon, roobetIcon } from "components/base/SVG";
import React, { useEffect, useRef, useState } from "react";

const BonusSelect = ({
  selected = null,
  list,
  key = null,
  onChange,
  placeholder,
  error = false,
}) => {
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(selected);

  const onClick = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);

    setActive(false);
  };

  const toggleActive = () => {
    setActive(!active);
  };

  useEffect(() => {
    setCurrentList(
      !currentSelected
        ? list
        : list.filter((item) => {
            let compareKey = key ?? "value";
            return item[compareKey] !== currentSelected[compareKey];
          })
    );
  }, [list, currentSelected]);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [selected]);

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  return (
    <div
      className={`bonusSelect ${active ? "active" : ""} ${
        error ? "error" : ""
      }`}
      ref={wrapper}
    >
      <div className="bonusSelect__selected" onClick={toggleActive}>
        <div className="bonusSelect__selected-inner">
          <div className="bonusSelect__selected-inner-icon">
            {currentSelected?.icon}
          </div>
          <p>{currentSelected ? currentSelected.value : placeholder}</p>
        </div>
        {chevronBottom}
      </div>
      <div className="bonusSelect__options">
        {currentList.map((item, index) => (
          <div
            className="bonusSelect__option"
            key={index}
            onClick={() => onClick(item)}
          >
            {item?.icon}
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BonusSelect;
