import { eyeCanIcon, eyeIcon } from "components/base/SVG";
import React, { useState } from "react";

export default function Input({
  type,
  name,
  value,
  onChange,
  label,
  placeholder,
  labelSvg,
  error = false,
  disabled,
}) {
  const [showPass, setShowPass] = useState(false);
  return (
    <div className="input__outer">
      {label && (
        <label htmlFor="">
          {labelSvg}
          {label}
        </label>
      )}

      <div
        className={
          "input " +
          (error ? "error " : "") +
          (type === "password" ? "wicon" : "")
        }
      >
        <input
          type={type === "password" ? (showPass ? "text" : "password") : type}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {type === "password" && (
          <button type="button" onClick={() => setShowPass(!showPass)}>
            {showPass ? eyeIcon : eyeCanIcon}
          </button>
        )}
      </div>
    </div>
  );
}
