import React from "react";
import ReactDom from "react-dom";
import { motion } from "framer-motion";

export default function Modal({ setModal, children, addClass }) {
  const closeFunc = (e) => {
    if (e.currentTarget === e.target) setModal(false);
  };
  return ReactDom.createPortal(
    <motion.div
      className={"modal " + (addClass ? addClass : "")}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
      // onClick={closeFunc}
    >
      {children}
    </motion.div>,
    document.getElementById("popups")
  );
}
