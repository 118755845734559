import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/inputs/Input";
import { kickLabelIcon } from "components/base/SVG";
import { handleToast } from "utils/toasts";
import { handleKickUsernameCheck } from "services/authService";

export default function Step4({ form, updateForm, steps, setCoins, coins }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  const handleNext = async () => {
    if (form?.kick_username === "") {
      steps.nextStep();
    } else {
      setDisabled(true);
      handleToast("info", "Checking Kick username for availability"); // Toast non traduit
      const kickUsernameCheck = await handleKickUsernameCheck(
        form?.kick_username
      );
      if (kickUsernameCheck === false) {
        setDisabled(false);
        return;
      }
      if (!kickUsernameCheck?.data?.available) {
        setDisabled(false);
        handleToast("error", "Kick username is already in use"); // Toast non traduit
        return;
      }
      setDisabled(false);
      setCoins(coins + 100);
      steps.nextStep();
    }
  };

  return (
    <div className="modalStep">
      <Input
        type={"text"}
        value={form?.kick_username}
        onChange={(e) => {
          updateForm({ kick_username: e.target.value });
        }}
        label={t("registerModStep4.usernameOptionalLabel")}
        labelSvg={kickLabelIcon}
        placeholder={t("registerModStep4.kickUsernamePlaceholder")}
      />

      <button
        type="button"
        className="button continue"
        disabled={disabled}
        onClick={handleNext}
      >
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{t("registerModStep4.continueButton")}</p>
      </button>
    </div>
  );
}
