import { checkIcon2 } from "components/base/SVG";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { vipBadges, vipLevelList } from "utils/constantLists";
import { formatCurrency, formatNumberComma } from "utils/globalFunc";
import RoobetLink from "./profileLinks/RoobetLink";
import { useDispatch, useSelector } from "react-redux";
import { claimUserVipReward, getUserVipData } from "services/vipService";
import { handleToast } from "utils/toasts";
import { updateUserInfo } from "store/redux/authSlice";

export default function VipTab() {
  const auth = useSelector((state) => state.auth);
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: true,
    loop: false,
    speed: 400,
    focusOnSelect: true,
    afterChange: (current) => setCurrentSliderIndex(current),
    // draggable: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [currentUnlocked, setCurrentUnlocked] = useState(0);
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);
  const [currentWager, setCurrentWager] = useState(700000);
  const [showTooltip, setShowTooltip] = useState(true);
  const [showBg, setShowBg] = useState(true);
  const [vipUserData, setVipUserData] = useState(null);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();
  const getVipData = async () => {
    let data = await getUserVipData();
    if (data === false) {
      return;
    }
    setVipUserData(data?.data);
    let index = data?.data?.vipUserEntries
      .map((item) => item?.claimed)
      .indexOf(false);
    setCurrentWager(Math.round(data?.data?.wagered));
    setCurrentUnlocked(index);
    setCurrentSliderIndex(index);
    setTimeout(() => {
      sliderRef?.current?.slickGoTo(index);
    }, 100);
  };

  const claimReward = async (id, index) => {
    let data = await claimUserVipReward(id);
    if (data === false) {
      return;
    }
    let arr = [...vipUserData?.vipUserEntries];
    arr[index] = {
      ...arr[index],
      claimed: data?.data?.claimed,
      claimedAt: data?.data?.claimedAt,
    };
    setVipUserData({ ...vipUserData, vipUserEntries: arr });
    dispatch(
      updateUserInfo({
        ...auth?.user,
        rank: { ...auth?.user?.rank, vipRank: index + 1 },
      })
    );
    handleToast("success", data?.message);
    setCurrentUnlocked(index + 1);
  };

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef?.current?.slickGoTo(currentUnlocked);
    }
  }, [currentUnlocked]);
  useEffect(() => {
    if (currentUnlocked === currentSliderIndex) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [currentSliderIndex, currentUnlocked]);

  useEffect(() => {
    if (
      auth?.user?.roobetUsername !== null ||
      auth?.user?.roobetUsername !== ""
    ) {
      getVipData();
    } else {
      setCurrentUnlocked(4);
      setCurrentSliderIndex(4);
      setTimeout(() => {
        sliderRef?.current?.slickGoTo(4);
      }, 100);
    }
  }, [auth?.user?.roobetUsername]);
  // console.log(vipUserData);
  return (
    <div className="modalProfile__vip">
      {(auth?.user?.roobetUsername === null ||
        auth?.user?.roobetUsername === "") && (
        <>
          <div className="modalProfile__vip-overlay"></div>
          <RoobetLink />
        </>
      )}

      <div className="modalProfile__title">
        <h3>Vip Progress</h3>
      </div>
      <div
        className="modalProfile__vip-slider-wrapper"
        onMouseOver={() => setShowBg(false)}
        onMouseLeave={() => setShowBg(true)}
      >
        {/* <AnimatePresence>
          {showBg && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0 }}
              className="modalProfile__vip-slider-wrapper-bg"
            ></motion.div>
          )}
        </AnimatePresence> */}

        <div className="modalProfile__vip-slider-outer">
          {auth?.user?.roobetUsername === null ||
          auth?.user?.roobetUsername === "" ? (
            <Slider
              {...settings}
              ref={sliderRef}
              className="modalProfile__vip-slider"
            >
              {vipLevelList.map((item, index) => {
                return (
                  <VipItem
                    key={index}
                    itemData={item}
                    currentWager={currentWager}
                    currentUnlocked={currentUnlocked}
                    setCurrentUnlocked={setCurrentUnlocked}
                    index={index}
                    modul={vipLevelList}
                    showTooltip={showTooltip}
                  />
                );
              })}
            </Slider>
          ) : (
            <Slider
              {...settings}
              ref={sliderRef}
              className="modalProfile__vip-slider"
            >
              {vipUserData?.vipUserEntries?.map((item, index) => {
                return (
                  <VipItem
                    key={index}
                    itemData={item}
                    currentWager={currentWager}
                    currentUnlocked={currentUnlocked}
                    setCurrentUnlocked={setCurrentUnlocked}
                    index={index}
                    modul={vipUserData?.vipUserEntries}
                    claimReward={() => claimReward(item?.id, index)}
                    showTooltip={showTooltip}
                  />
                );
              })}
            </Slider>
          )}
        </div>
      </div>
      <div className="modalProfile__vip-desc">
        <p>
          Climb the ranks and unlock our industry leading VIP rank up rewards. Over $1,000,000 is available for each player to claim per month, if you require a higher set of bonuses, we can discuss the privately to tailor your needs. Happy Wagering, Gamble responsibly.
        </p>
      </div>
    </div>
  );
}
const VipItem = ({
  itemData,
  currentWager,
  currentUnlocked,
  setCurrentUnlocked,
  index,
  modul,
  showTooltip,
  claimReward = () => {},
}) => {
  const [claimed, setClaimed] = useState(itemData?.claimed);
  const [currLine, setCurrLine] = useState("");
  const [nextLine, setNextLine] = useState("");
  const [showCurr, setShowCurr] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const vipRankNames = [
    "Recruit",
    "Apprentice",
    "Initiate",
    "Squire",
    "Vanguard",
    "Knight",
    "Elite",
    "Warlord",
    "Champion",
    "Conqueror",
    "Overlord",
    "Demi-God",
    "Immortal",
      "Secret"
  ];

  useEffect(() => {
    let prevNeeded = modul[index - 1]?.wagered;
    let prevHalf = (itemData?.wagered - prevNeeded) / 2;
    let nextNeeded = modul[index + 1]?.wagered;
    let nextHalf = (nextNeeded - itemData?.wagered) / 2;
    if (currentWager >= itemData?.wagered) {
      setCurrLine("100%");
      setShowCurr(false);
      setShowNext(true);
      if (currentWager - itemData?.wagered <= nextHalf) {
        setNextLine(
          `${((currentWager - itemData?.wagered) * 100) / nextHalf}%`
        );
      } else {
        setNextLine("100%");
        setShowNext(false);
      }
    } else {
      setNextLine("0%");
      setShowCurr(true);
      setShowNext(false);
      if (index !== 0) {
        if (currentWager - prevNeeded > prevHalf) {
          setCurrLine(
            `0%`
          );
        } else {
          setCurrLine("0%");
          setShowCurr(false);
        }
      } else {
        setCurrLine(`0%`);
      }
    }
  }, []);
  useEffect(() => {
    setClaimed(itemData?.claimed);
  }, [itemData]);
  return (
    <>
      <div className="modalProfile__vip-slider-item-outer">
        <div
            className={
                "modalProfile__vip-slider-item " +
                (currentUnlocked === index ? "current" : "") +
                (claimed ? "claimed" : "")
            }
        >
          <div
              className={
                  "modalProfile__vip-slider-item-level " +
                  (currentWager >= itemData?.wagered ? "active" : "")
              }
          >
            <p>{index + 1}</p>
          </div>
          <AnimatePresence>
            {showTooltip && (
                <motion.div
                    className="modalProfile__vip-slider-tooltip-wrapper"
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{duration: 0.3}}
                    exit={{opacity: 0}}
                >
                  <div className="modalProfile__vip-slider-tooltip-outer">
                    {showCurr && (
                        <div
                            className="modalProfile__vip-slider-tooltip"
                            style={{
                              left: currLine,
                            }}
                        >
                          <p>
                            ${formatCurrency(currentWager.toString())}{" "}
                            <span>to go</span>
                          </p>
                        </div>
                    )}
                  </div>
                  <div className="modalProfile__vip-slider-tooltip-outer">
                    {showNext && (
                        <div
                            className="modalProfile__vip-slider-tooltip"
                            style={{
                              left: nextLine,
                            }}
                        >
                          <p>
                            ${formatCurrency(currentWager.toString())}{" "}
                            <span>to go</span>
                          </p>
                        </div>
                    )}
                  </div>
                </motion.div>
            )}
          </AnimatePresence>
          <div className="modalProfile__vip-slider-item-progress">
            <div className="modalProfile__vip-slider-item-progress-line">
              <span
                  style={{
                    width: currLine,
                  }}
              ></span>
            </div>
            <div className="modalProfile__vip-slider-item-progress-line">
              <span
                  style={{
                    width: nextLine,
                  }}
              ></span>
            </div>
          </div>
          <div className="modalProfile__vip-slider-item-arrow"></div>
          <div className="modalProfile__vip-slider-item-image">
            <img src={vipBadges[index]} alt=""/>
          </div>
          <h5>{vipRankNames[index] || "Secret Rank"}</h5>
          {claimed ? (
              <div className="modalProfile__vip-slider-item-icon">
                {checkIcon2}
              </div>
          ) : (
              <>
                {currentUnlocked === index ? (
                    <h6>
                      Claim Now !
                    </h6>
                ) : (
                    <>
                      {currentWager >= itemData?.wagered ? (
                          <h6>
                            Claim Now !
                          </h6>
                      ) : (
                          <h6>
                            <span>${formatCurrency(itemData?.wagered.toString())}</span>{" "}
                            Wager Needed
                          </h6>
                      )}
                    </>
                )}
              </>
          )}
        </div>
        {claimed ? (
            <button
                type="button"
                className="modalProfile__vip-slider-item-claimed"
          >
            <p>Claimed</p>
          </button>
        ) : (
          <>
            {!claimed && currentWager >= itemData?.wagered ? (
              <button
                type="button"
                className="modalProfile__vip-slider-item-btn"
                onClick={claimReward}
              >
                <span className="default"></span>
                <span className="hov"></span>
                <span className="active"></span>
                <p>
                  Claim $<b>{formatCurrency(itemData?.reward.toString())}</b>
                </p>
              </button>
            ) : (
              <button
                type="button"
                className="modalProfile__vip-slider-item-disabled"
              >
                <p>
                  Claim $<b>{formatCurrency(itemData?.reward.toString())}</b>
                </p>
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};
