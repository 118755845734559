import latest1 from "assets/images/latest/1.png";
import latest2 from "assets/images/latest/2.png";
import latest3 from "assets/images/latest/3.png";
import latest4 from "assets/images/latest/4.png";
import offersPic1 from "assets/images/offers/1.png";
import offersPic2 from "assets/images/offers/2.png";
import offersPic3 from "assets/images/offers/3.png";
import offersPic4 from "assets/images/offers/4.png";
import offersPic5 from "assets/images/offers/5.png";
import engFlag from "assets/images/flags/eng.png";
import espFlag from "assets/images/flags/esp.png";
import frFlag from "assets/images/flags/fr.png";
import hiFlag from "assets/images/flags/hi.png";

import rank1 from "assets/images/ranks/rank1.png";
import rank2 from "assets/images/ranks/rank2.png";
import rank3 from "assets/images/ranks/rank3.png";
import rank4 from "assets/images/ranks/rank4.png";
import rank5 from "assets/images/ranks/rank5.png";
import rank6 from "assets/images/ranks/rank6.png";
import rank7 from "assets/images/ranks/rank7.png";
import rank8 from "assets/images/ranks/rank8.png";
import rank9 from "assets/images/ranks/rank9.png";
import rank10 from "assets/images/ranks/rank10.png";
import rank11 from "assets/images/ranks/rank11.png";
import rank12 from "assets/images/ranks/rank12.png";
import rank13 from "assets/images/ranks/rank13.png";

import { generateArrayOfYears } from "./globalFunc";
import { packdrowIcon, roobetIcon } from "components/base/SVG";
export const monthList = [
  { id: "1", value: "01" },
  { id: "2", value: "02" },
  { id: "3", value: "03" },
  { id: "4", value: "04" },
  { id: "5", value: "05" },
  { id: "6", value: "06" },
  { id: "7", value: "07" },
  { id: "8", value: "08" },
  { id: "9", value: "09" },
  { id: "10", value: "10" },
  { id: "11", value: "11" },
  { id: "12", value: "12" },
];
export const yearList = generateArrayOfYears(124);
export const latestList = [
  {
    id: "1",
    image: latest1,
  },
  {
    id: "2",
    image: latest2,
  },
  {
    id: "3",
    image: latest3,
  },
  {
    id: "4",
    image: latest4,
  },
  {
    id: "5",
    image: latest3,
  },
  {
    id: "6",
    image: latest2,
  },
  {
    id: "7",
    image: latest1,
  },
  {
    id: "8",
    image: latest4,
  },
];

export const offersList = [
  {
    id: "1",
    image: offersPic1,
    bonus: (
      <>
        <span>100</span> Free Spins
      </>
    ),
    title: "Roobet",
    bet: "",
    wager: "$10.000",
    disabled: false,
    link: "https://roobet.com/?ref=foss",
    modal: "offer100",
  },
  {
    id: "2",
    image: offersPic2,
    bonus: (
      <>
        <span>$4.000</span> Free Spins
      </>
    ),
    title: "Roobet",
    bet: "",
    wager: "$500.000",
    disabled: false,
    link: "https://roobet.com/?ref=foss",
    modal: "offer4000",
  },
  {
    id: "3",
    image: offersPic3,
    bonus: (
      <>
        <span>10%</span> Deposit
      </>
    ),
    title: "Packdraw",
    bet: "",
    wager: "NO Wager",
    disabled: false,
    link: "https://packdraw.com/?ref=foss",
    modal: "offerDeposit",
  },
  {
    id: "4",
    image: offersPic4,
    bonus: (
      <>
        <span>Claim Rolex</span>
      </>
    ),
    title: "Packdraw",
    bet: "",
    wager: "$1,000.000",
    disabled: false,
    link: "https://packdraw.com/?ref=foss",
    modal: "offerRolex",
  },
  {
    id: "5",
    image: offersPic5,
    bonus: (
      <>
        <span>Claim iPhone</span>
      </>
    ),
    title: "Packdraw",
    bet: "",
    wager: "$50,000",
    disabled: false,
    link: "https://packdraw.com/?ref=foss",
    modal: "offerIphone",
  },
];

export const languageList = [
  {
    id: "1",
    code: "en",
    language: "English",
    flag: engFlag,
    nextId: "2",
  },
  {
    id: "2",
    code: "es",
    language: "Spanish",
    flag: espFlag,
    nextId: "3",
  },
  {
    id: "3",
    code: "fr",
    language: "French",
    flag: frFlag,
    nextId: "4",
  },
  {
    id: "4",
    code: "hi",
    language: "Hindi",
    flag: hiFlag,
    nextId: "1",
  },
];

export const ticketHoldersList = [
  {
    id: "1",
    no: "1",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: true,
  },
  {
    id: "2",
    no: "2",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "3",
    no: "3",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "4",
    no: "4",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "5",
    no: "5",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "6",
    no: "6",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "7",
    no: "7",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "8",
    no: "8",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "9",
    no: "9",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
  {
    id: "10",
    no: "10",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "4.560",
    is_firstPlace: false,
  },
];
export const ticketHoldersListPack = [
  {
    id: "1",
    no: "1",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "2",
    no: "2",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "3",
    no: "3",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "4",
    no: "4",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "5",
    no: "5",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "6",
    no: "6",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "7",
    no: "7",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "8",
    no: "8",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "9",
    no: "9",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
  {
    id: "10",
    no: "10",
    user: "Fossyvroom",
    wagered: "$3,000,000",
    prize: "€5.000",
  },
];
export const packdrawPrizes = [
  10000, 4000, 1500, 1000, 500, 350, 300, 250, 200, 175, 150, 125, 100, 100, 75,
  75, 50, 50, 50, 50,
];
export const vipLevelList = [
  {
    id: "1",
    claimed: true,
    reward: 25,
    wagered: 10000,
  },
  {
    id: "2",
    claimed: true,
    reward: 100,
    wagered: 50000,
  },
  {
    id: "3",
    claimed: false,
    reward: 200,
    wagered: 100000,
  },
  {
    id: "4",
    claimed: true,
    reward: 500,
    wagered: 250000,
  },
  {
    id: "5",
    claimed: false,
    reward: 1000,
    wagered: 500000,
  },
  {
    id: "6",
    claimed: false,
    reward: 2500,
    wagered: 1000000,
  },
  {
    id: "7",
    claimed: false,
    reward: 5000,
    wagered: 2000000,
  },
  {
    id: "8",
    claimed: false,
    reward: 15000,
    wagered: 5000000,
  },
  {
    id: "9",
    claimed: false,
    reward: 30000,
    wagered: 10000000,
  },
  {
    id: "10",
    claimed: false,
    reward: 50000,
    wagered: 25000000,
  },
  {
    id: "11",
    claimed: false,
    reward: 100000,
    wagered: 50000000,
  },
  {
    id: "12",
    claimed: false,
    reward: 200000,
    wagered: 100000000,
  },
  {
    id: "13",
    claimed: false,
    reward: 1000000,
    wagered: 500000000,
  },
];
export const profileLinkList = [
  {
    id: "1",
    title: "Roobet",
  },
  {
    id: "2",
    title: "Kick",
  },
  {
    id: "3",
    title: "Discord",
  },
];

export const vipBadges = [
  rank1,
  rank2,
  rank3,
  rank4,
  rank5,
  rank6,
  rank7,
  rank8,
  rank9,
  rank10,
  rank11,
  rank12,
  rank13,
];
export const vipRankNames = [
  "Recruit",
  "Apprentice",
  "Initiate",
  "Squire",
  "Vanguard",
  "Knight",
  "Elite",
  "Warlord",
  "Champion",
  "Conqueror",
  "Overlord",
  "Demi-God",
  "Immortal",
  "Secret",
];
export const bonusSelectList = [
  {
    id: "1",
    value: "Roobet",
    icon: roobetIcon,
  },
  {
    id: "2",
    value: "Packdraw",
    icon: packdrowIcon,
  },
];

export const bonusList = [
  {
    id: "roobet-100-freespins",
    title: "100 Free Spins - Roobet",
    description:
      'Wager $10,000 on slots under code "Foss" and Receive x100 $1 free spins on Sweet Bonanza 1000.',
    imageURL: offersPic1,
    requiredWager: 10000.0,
    bonusCategory: "ROOBET",
  },
  {
    id: "roobet-4000-freespins",
    title: "$4,000 Free Spins - Roobet",
    description:
      'Wager $500,000 on slots under code "Foss" and Receive $4,000 in FREE SPINS on ANY GAME, on ANY BET SIZE!',
    imageURL: offersPic2,
    requiredWager: 500000.0,
    bonusCategory: "ROOBET",
  },
  {
    id: "packdraw-10percent",
    title: "10% DEPOSIT BONUS - PackDraw",
    description:
      "10% Deposit Bonus on your FIRST DEPOSIT ONLY. No wager requirement. Unlimited deposit size.",
    imageURL: offersPic3,
    requiredWager: 0.0,
    bonusCategory: "PACKDRAW",
  },
  {
    id: "packdraw-rolex-submarine",
    title: "ROLEX SUBMARINE - PackDraw",
    description:
      'Wager $1,000,000 on Packdraw under code "Foss" and Receive Rolex Submariner or $17,500.',
    imageURL: offersPic4,
    requiredWager: 1000000.0,
    bonusCategory: "PACKDRAW",
  },
  {
    id: "packdraw-iphone-16-pro-max",
    title: "IPHONE 16 PRO MAX - PackDraw",
    description:
      "Wager $50,000 within 24 hours of creating your account under code “Foss”, and receive a free iPhone 16 Pro Max or $1,100 to instantly withdraw.",
    imageURL: offersPic5,
    requiredWager: 50000.0,
    bonusCategory: "PACKDRAW",
  },
];
