// src/components/inputs/LanguageSelect.jsx
import React, { useEffect, useRef, useState } from "react";
import { chevronBottom } from "components/base/SVG";
import { useTranslation } from 'react-i18next';

const LanguageSelect = ({
  list,
  keyField = "code", // Renommé de 'key' pour éviter les conflits avec les props React
  onChange, // Callback optionnel
  placeholder,
}) => {
  const { i18n } = useTranslation();
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [currentList, setCurrentList] = useState(list);
  const [currentSelected, setCurrentSelected] = useState(null);

  // Fonction pour extraire le code de langue de base (ex. 'fr' de 'fr-FR')
  const getBaseLangCode = (langCode) => {
    return langCode.split('-')[0];
  };

  // Initialiser currentSelected basé sur la langue actuelle d'i18n
  useEffect(() => {
    const currentLangCode = getBaseLangCode(i18n.language);
    const selectedLanguage = list.find(lang => lang[keyField] === currentLangCode);
    if (selectedLanguage) {
      setCurrentSelected(selectedLanguage);
    } else {
      // Fallback si la langue actuelle n'est pas dans la liste
      setCurrentSelected(list[0]); // ou null selon votre besoin
    }
  }, [i18n.language, list, keyField]);

  // Mettre à jour currentList quand currentSelected change
  useEffect(() => {
    setCurrentList(
      !currentSelected
        ? list
        : list.filter((item) => item[keyField] !== currentSelected[keyField])
    );
  }, [list, currentSelected, keyField]);

  const toggleActive = () => {
    setActive(!active);
  };

  const handleLanguageChange = (item) => {
    setCurrentSelected(item);
    if (onChange) onChange(item);
    i18n.changeLanguage(item[keyField]);
    setActive(false);
  };

  // Fermer le dropdown quand on clique en dehors
  useEffect(() => {
    const windowClick = ({ target }) => {
      if (wrapper.current && !wrapper.current.contains(target)) {
        setActive(false);
      }
    };

    if (active) {
      window.addEventListener("click", windowClick);
    } else {
      window.removeEventListener("click", windowClick);
    }

    return () => window.removeEventListener("click", windowClick);
  }, [active]);

  return (
    <div className={`languageSelect ${active ? "active" : ""}`} ref={wrapper}>
      <div className="languageSelect__selected" onClick={toggleActive}>
        <div className="languageSelect__selected-image">
          {currentSelected ? (
            <img src={currentSelected.flag} alt={currentSelected.code} />
          ) : (
            placeholder && <span>{placeholder}</span>
          )}
        </div>
        {/* <div className="languageSelect__chevron">
          {chevronBottom}
        </div> */}
      </div>
      {active && (
        <div className="languageSelect__options">
          {currentList.map((item) => (
            <div
              className="languageSelect__option"
              key={item[keyField]}
              onClick={() => handleLanguageChange(item)}
            >
              <div className="languageSelect__option-image">
                <img src={item.flag} alt={item.code} />
              </div>
              <div className="languageSelect__option-label">
                {item.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelect;
