import { closeIcon, editIcon, plusIcon } from "components/base/SVG";
import React, { useRef, useState } from "react";
import lvl1 from "assets/images/levels/lvl1.png";
import rank from "assets/images/ranks/rank.png";
import { useDispatch, useSelector } from "react-redux";
import { handleToast } from "utils/toasts";
import { deleteUserPicture, postUserPicture } from "services/accountService";
import { updateUserInfo } from "store/redux/authSlice";
import RoobetLink from "./profileLinks/RoobetLink";
import KickLink from "./profileLinks/KickLink";
import DiscordLink from "./profileLinks/DiscordLink";
import { vipBadges } from "utils/constantLists";

export default function ProfileTab() {
  const auth = useSelector((state) => state.auth);
  const fileRef = useRef();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleImageUpload = async (e) => {
    setDisabled(true);
    handleToast("info", "Updating profile picture");
    const imageFile = e.target.files[0];
    e.target.value = null;
    if (imageFile.size > 1024 * 1024 * 10) {
      handleToast("warning", "File too big, Max size 5MB");
      setDisabled(false);
      return;
    }
    let data = await postUserPicture(imageFile);
    if (data === false) {
      setDisabled(false);
      return;
    }
    setDisabled(false);
    handleToast("success", data?.message);
    dispatch(updateUserInfo({ ...auth?.user, profilePicture: data?.data }));
  };

  const deletePicture = async () => {
    setDisabled(true);
    const data = await deleteUserPicture();
    if (data === false) {
      setDisabled(false);
      return;
    }
    setDisabled(false);
    handleToast("success", data?.message);
    dispatch(updateUserInfo({ ...auth?.user, profilePicture: null }));
  };
  return (
    <div className="modalProfile__prof">
      <div className="modalProfile__title mb">
        <h3>Profile</h3>
      </div>
      <div className="modalProfile__prof-inner">
        <div className="modalProfile__prof-row">
          <div className="modalProfile__prof-avatar">
            <div className="modalProfile__prof-avatar-image-outer">
              {auth?.user?.profilePicture ? (
                <div className="modalProfile__prof-avatar-image">
                  <img src={auth?.user?.profilePicture} alt="" />
                </div>
              ) : (
                <div className="modalProfile__prof-avatar-icon">
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    ref={fileRef}
                    accept="image/*"
                    disabled={disabled}
                  />
                  {plusIcon}
                </div>
              )}
            </div>
            {auth?.user?.profilePicture && (
              <div className="modalProfile__prof-avatar-btns">
                <button
                  type="button"
                  className="modalProfile__prof-avatar-btn edit"
                >
                  <input
                    type="file"
                    onChange={handleImageUpload}
                    ref={fileRef}
                    disabled={disabled}
                    accept="image/*"
                  />
                  {editIcon}
                </button>
                <button
                  type="button"
                  className="modalProfile__prof-avatar-btn"
                  onClick={deletePicture}
                >
                  {closeIcon}
                </button>
              </div>
            )}
          </div>
          <div className="modalProfile__prof-info">
            <div className="modalProfile__prof-level">
              <div className="modalProfile__prof-level-image">
                <img src={lvl1} alt="" />
              </div>
              <h6>{auth?.user?.username}</h6>
            </div>
            <div className="modalProfile__prof-ranks">
              <div className="modalProfile__prof-rank">
                <div className="modalProfile__prof-rank-image">
                  {auth?.user?.rank?.vipRank !== 0 && (
                    <img
                      src={vipBadges[auth?.user?.rank?.vipRank - 1]}
                      alt=""
                    />
                  )}
                </div>
              </div>
              <div className="modalProfile__prof-rank">
                <div className="modalProfile__prof-rank-icon">{plusIcon}</div>
              </div>
              <div className="modalProfile__prof-rank">
                <div className="modalProfile__prof-rank-icon">{plusIcon}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalProfile__prof-content">
          <RoobetLink />
          <KickLink />
          <DiscordLink />
        </div>
      </div>
    </div>
  );
}
