import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { setBalance, setUnreadNotification } from "store/redux/authSlice";

const WebSocketContext = createContext(null);

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children, token }) => {
  const dispatch = useDispatch();
  const [ws, setWs] = useState(null);
  const reconnectRef = useRef(false); // Pour éviter les connexions en boucle
  const retryDelay = useRef(1000); // Temps initial pour le backoff

  const connectWebSocket = () => {
    if (!token || reconnectRef.current) return;

    reconnectRef.current = true; // Indiquer qu'une connexion est en cours
    // const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const protocol = "wss";
    const socketUrl = `${protocol}://${process.env.REACT_APP_WS}/ws/roobetreloads?token=${token}`;
    const socket = new WebSocket(socketUrl);

    socket.onopen = () => {
      console.log("WebSocket connected");
      setWs(socket);
      reconnectRef.current = false; // La connexion est réussie
      retryDelay.current = 1000; // Réinitialiser le délai pour les prochaines tentatives
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === "balance_update" && data.balance !== undefined) {
          dispatch(setBalance(data.balance));
        }

        if (
          data.type === "notifications_update" &&
          data.unreadCount !== undefined
        ) {
          dispatch(setUnreadNotification(data.unreadCount));
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.warn("WebSocket connection closed. Retrying...");
      setWs(null);
      reconnectRef.current = false;

      // Reconnecter avec un délai croissant (backoff)
      setTimeout(() => {
        retryDelay.current = Math.min(retryDelay.current * 2, 30000); // Max 30 secondes
        connectWebSocket();
      }, retryDelay.current);
    };
  };

  useEffect(() => {
    if (token) {
      connectWebSocket();
    }

    // Nettoyage pour fermer la connexion lors de la destruction
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [token]);

  return (
    <WebSocketContext.Provider value={{ ws }}>
      {children}
    </WebSocketContext.Provider>
  );
};
