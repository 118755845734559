import { closeIcon } from "components/base/SVG";
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import rolex2 from "assets/images/store/rolex2.png";
import pc from "assets/images/store/pc.png";
import macbook from "assets/images/store/macbook.png";
import iphone2 from "assets/images/store/iphone2.png";
import airpods from "assets/images/store/airpods.png";
import tip from "assets/images/store/tip.png";
import steam from "assets/images/store/steam.png";
import amazon from "assets/images/store/amazon.png";
import shardIcon from "assets/icons/shardIcon.png";
import { formatNumberComma } from "utils/globalFunc";
import Markdown from "react-markdown";
import { buyShopHandler } from "services/shopService";
import { handleToast } from "utils/toasts";
import { useSelector } from "react-redux";

export default function ShopItemMod({ setModal, modalData, setModalData }) {
  const [disabled, setDisabled] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const clickHandler = async () => {
    setDisabled(true);
    let data = await buyShopHandler(modalData?.id, auth?.isLogged);
    if (data === false) {
      setDisabled(false);
      return;
    }
    handleToast("success", data?.message);
    setDisabled(false);
    setModal(null);
    setModalData(null);
  };
  return (
    <div className="modalShop">
      <div className="modalShop__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalShop__content">
        <div className="modalShop__content-image-outer">
          <div className="modalShop__content-image">
            {(modalData?.id === "rolex-datejust-41" ||
              modalData?.id === "rolex-datejust-41-high") && (
              <img src={rolex2} alt="image" />
            )}
            {modalData?.id === "gamingpc-4080ti" && (
              <img src={pc} alt="image" />
            )}
            {modalData?.id === "macbook-pro-m3" && (
              <img src={macbook} alt="image" />
            )}
            {(modalData?.id === "iphone-16pro" ||
              modalData?.id === "iphone-16pro-high") && (
              <img src={iphone2} alt="image" />
            )}
            {modalData?.id === "airpodspro2" && (
              <img src={airpods} alt="image" />
            )}
            {modalData?.id?.includes("tip") && <img src={tip} alt="image" />}

            {modalData?.id === "steam100giftcard" && (
              <img src={steam} alt="image" />
            )}
            {modalData?.id === "amazon100giftcard" && (
              <img src={amazon} alt="image" />
            )}
            {/* <img src={itemData?.imageURL} alt="image" /> */}
          </div>
        </div>
        <div className="modalShop__content-info">
          <h3>
            <span>CLAIM</span> {modalData?.title}
          </h3>
          <div className="modalShop__content-desc">
            <Markdown>{modalData?.modalMarkdown}</Markdown>
          </div>
        </div>
      </div>
      <div className="modalShop__info">
        <button
          type="button"
          className="modalShop__info-btn"
          onClick={clickHandler}
        >
          <span className="default"></span>
          <span className="hov"></span>
          <p>{formatNumberComma(modalData?.price?.toString())}</p>
          <img src={shardIcon} alt="shardIcon" />
        </button>
      </div>
    </div>
  );
}
