import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { checkIcon, fossCasIcon } from "components/base/SVG";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handleRegister } from "services/authService";
import { loginUser } from "store/redux/authSlice";
import { handleToast } from "utils/toasts";
import shards from "assets/images/shards/shards.png"
export default function Step5({ setModal, coins, form }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setDisabled(true);
    const birthDayString = `${form?.byear?.value}-${form?.bmonth?.value}-${form?.bday}`;
    const birthdayDate = moment(birthDayString).format("YYYY-MM-DD");
    const registerData = await handleRegister(
      form?.username,
      form?.email,
      form?.password,
      birthdayDate,
      form?.kick_username
    );
    if (registerData === false) {
      setDisabled(false);
      handleToast("error", "Something went wrong"); // Toast non traduit
      return;
    }
    localStorage.setItem("token", registerData?.data?.token);
    dispatch(loginUser(registerData?.data?.user));
    handleToast("success", "Registered successfully"); // Toast non traduit
    setDisabled(false);
    setModal(null);
  };

  return (
    <div className="modalStep">
      <AnimatePresence>
        {isClaimed ? (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            type="button"
            className={"modalStep__claimed active"}
            onClick={() => setIsClaimed(!isClaimed)}
          >
            <span className="default"></span>
            <span className="hov"></span>
            <span className="active"></span>
            <div className="modalStep__claimed-inner">
              <p>{t("registerModStep5.claimedText")}</p>
              {checkIcon}
            </div>
          </motion.button>
        ) : (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            type="button"
            className={"modalStep__btn"}
            onClick={() => setIsClaimed(!isClaimed)}
          >
            <span className="default"></span>
            <span className="hov"></span>
            <span className="active"></span>
            <div className="modalStep__btn-inner">
              <p>{t("registerModStep5.claimText", { coins })}</p>
              <img
                src={shards}
                alt="Shards Icon"
                style={{
                  zIndex: 10,
                  position: "relative",
                  paddingLeft: 10
                }}
              />
            </div>
          </motion.button>
        )}
      </AnimatePresence>

      <button
        type="button"
        className="button continue"
        disabled={disabled}
        onClick={handleSubmit}
      >
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{t("registerModStep5.continueButton")}</p>
      </button>
    </div>
  );
}
