import { closeIcon } from "components/base/SVG";
import React from "react";
import character from "assets/images/store/character.png";

export default function BonusSuccessModal({ setModal }) {
  return (
    <div className="modalSuccess">
      <div className="modalSuccess__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>

      <div className="modalSuccess__title">
        <h6>Thanks for your request</h6>
      </div>
      <div className="modalSuccess__inner">
        <div className="modalSuccess__image">
          <img src={character} alt="character" />
        </div>
        <div className="modalSuccess__desc">
          <h6>Bonus claimed successfully!</h6>
          <p>The team will process your bonus shortly.</p>
        </div>
      </div>
    </div>
  );
}
