import { handleError, instance } from "./api";

export const getShopData = async () => {
  try {
    const result = await instance.get("/shop");
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const buyShopHandler = async (id, isLogged) => {
  try {
    const result = await instance.post(
      "/shop/buy",
      { id },
      isLogged && {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};
