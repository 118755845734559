import React from "react";
import rolex from "assets/images/store/rolex.png";
import iphone from "assets/images/store/iphone.png";
import shardIcon from "assets/icons/shardIcon.png";
import { formatNumberComma } from "utils/globalFunc";

export default function TopPrizeCard({ itemData,onClick }) {
  return (
    <div className="store__top-item">
      <div className="store__top-item-image-outer">
        <div className="store__top-item-image">
          {itemData?.id === "rolex-datejust-41-high" && (
            <img src={rolex} alt="image" />
          )}
          {itemData?.id === "iphone-16pro-high" && (
            <img src={iphone} alt="image" />
          )}
          {/* <img src={itemData?.imageURL} alt="image" /> */}
        </div>
      </div>

      <div className="store__top-item-content">
        <div className="store__top-item-content-title">
          <h2>
            {itemData?.title?.split(" ")[0]}{" "}
            <span>{itemData?.title?.split(" ")?.slice(1)?.join(" ")}</span>
          </h2>
          <p>{itemData?.description}</p>
        </div>
        <button className="button buy" onClick={onClick}>
          <span className="default"></span>
          <span className="hov"></span>
          <span className="active"></span>
          <p>{formatNumberComma(itemData?.price?.toString())}</p>
          <img src={shardIcon} alt="shardIcon" />
        </button>
        <div className="store__top-item-content-stock">
          <p>{itemData?.stock} Available</p>
        </div>
      </div>
    </div>
  );
}
