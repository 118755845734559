import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLogged: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action) {
      state.isLogged = true;
      state.user = action.payload;
    },
    logoutUser(state) {
      state.isLogged = false;
      state.user = null;
      localStorage.removeItem("access");
    },
    updateUserInfo(state, action) {
      state.user = action.payload;
    },
    setBalance: (state, action) => {
      state.user.balance = action.payload;
    },
    setUnreadNotification: (state, action) => {
      state.user.notificationSummary.countUnread = action.payload;
    },
  },
});

export const { loginUser, logoutUser, updateUserInfo, setBalance, setUnreadNotification } = authSlice.actions;

export default authSlice.reducer;
