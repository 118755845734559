import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/inputs/Input";
import CustomSelect from "components/inputs/CustomSelect";
import { monthList, yearList } from "utils/constantLists";
import { formatNumber } from "utils/globalFunc";
import { handleToast } from "utils/toasts";
import moment from "moment";

export default function Step3({ form, updateForm, steps, setCoins, coins }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false); // Optionnel, si vous souhaitez désactiver le bouton pendant le traitement

  const handleNext = () => {
    const birthDayString = `${form?.byear?.value}-${form?.bmonth?.value}-${form?.bday}`;
    const birthdayDate = moment(birthDayString).format("YYYY-MM-DD");

    if (form?.bday === "" || form?.bmonth === "" || form?.byear === "") {
      setError(true);
      if (form?.bday === "") {
        handleToast("error", "Please enter birth day"); // Toast non traduit
      }
      if (form?.bmonth === "") {
        handleToast("error", "Please enter birth month"); // Toast non traduit
      }
      if (form?.byear === "") {
        handleToast("error", "Please enter birth year"); // Toast non traduit
      }
    } else if (moment().diff(birthdayDate, "years") < 18) {
      handleToast("error", "You must be at least 18 years old"); // Toast non traduit
    } else {
      setError(false);
      setCoins(coins + 100);
      steps.nextStep();
    }
  };

  return (
    <div className="modalStep">
      <div className="input__row">
        <div className="input__row-label">{t("registerModStep3.birthdayLabel")}</div>
        <div className="input__row-inner">
          <Input
            type={"text"}
            value={form?.bday}
            onChange={(e) => {
              if (formatNumber(e.target.value) <= 31) {
                updateForm({ bday: formatNumber(e.target.value) });
              }
            }}
            placeholder={t("registerModStep3.bdayPlaceholder")} // "DD"
            error={error && form?.bday === ""}
          />
          <CustomSelect
            list={monthList}
            selected={form?.bmonth || null}
            onChange={(data) => {
              updateForm({ bmonth: data });
            }}
            placeholder={t("registerModStep3.bmonthPlaceholder")} // "Month"
            error={error && form?.bmonth === ""}
          />
          <CustomSelect
            list={yearList}
            selected={form?.byear || null}
            onChange={(data) => {
              updateForm({ byear: data });
            }}
            placeholder={t("registerModStep3.byearPlaceholder")} // "YYYY"
            error={error && form?.byear === ""}
          />
        </div>
      </div>

      <button
        type="button"
        className="button continue"
        onClick={handleNext}
        disabled={disabled}
      >
        <span className="default"></span>
        <span className="hov"></span>
        <span className="active"></span>
        <p>{t("registerModStep3.continueButton")}</p>
      </button>
    </div>
  );
}
