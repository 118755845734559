import React, { useState } from "react";
import Input from "components/inputs/Input";
import { checkIcon } from "components/base/SVG";
import { getDiscordOAuthLink } from "services/discordService";
import { useSelector } from "react-redux";

export default function DiscordLink() {
  const auth = useSelector((state) => state.auth);
  const [value, setValue] = useState(auth?.user?.discordUser?.discordUsername);
  const [isLinked, setIsLinked] = useState(
    auth?.user?.discordUser?.discordLinked
  );
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="modalProfile__prof-content-item">
      <div className="modalProfile__prof-content-item-title">
        <p>Discord</p>
      </div>
      <div className="modalProfile__prof-content-item-row">
        <div className="modalProfile__prof-content-item-row-inner">
          <div className="modalProfile__prof-content-item-input">
            <Input
              type={"text"}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={""}
              disabled={true}
            />
          </div>
          {isLinked ? (
            <button
              type="button"
              className={
                "button link " + (isLinked || isLoading ? "linked" : "")
              }
              target="_blank"
            >
              {isLoading ? (
                checkIcon
              ) : (
                <>{isLinked ? <p>Linked</p> : <p>Link</p>}</>
              )}
            </button>
          ) : (
            <a
              className={
                "button link " + (isLinked || isLoading ? "linked" : "")
              }
              href={getDiscordOAuthLink()}
              target="_blank"
            >
              {isLoading ? (
                checkIcon
              ) : (
                <>{isLinked ? <p>Linked</p> : <p>Link</p>}</>
              )}
            </a>
          )}
        </div>

        {/* <button type="button" className="modalProfile__prof-content-item-btn">
            <p>Change account</p>
          </button> */}
      </div>
    </div>
  );
}
