import { handleError, instance } from "./api";

export const getUserVipData = async () => {
  try {
    const result = await instance.get("/vip/user", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const claimUserVipReward = async (id) => {
  try {
    const result = await instance.post(
      "/vip/claim",
      { id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};
