import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { closeIcon, notifyIcon } from "./SVG";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  generateFakeNotifications,
  getNotificationsSummary,
  markAsReadNotification,
} from "services/notificationService";
import { updateUserInfo } from "store/redux/authSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import infoNotif from "assets/images/notifications/infoNotif.png";
import kickNotif from "assets/images/notifications/kickNotif.png";
import gtbNotif from "assets/images/notifications/gtbNotif.png";
import rewardsNotif from "assets/images/notifications/rewardsNotif.png";
import vipNotif from "assets/images/notifications/vipNotif.png";

export default function Notification({ setModal, modal }) {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const wrapper = useRef(null);
  const [active, setActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [notifications, setNotifications] = useState(
    auth?.user?.notificationSummary
      ? [...auth?.user?.notificationSummary?.notifications].sort(
          (a, b) => new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
        )
      : []
  );
  const toggleActive = () => {
    if (mobile) {
      if (modal === "notif") {
        setModal(null);
      } else {
        setModal("notif");
      }
    } else {
      setActive(!active);
    }
  };

  useEffect(() => {
    const windowClick = ({ target }) => {
      if (!wrapper.current.contains(target)) setActive(false);
    };

    if (active) window.addEventListener("click", windowClick);
    else window.removeEventListener("click", windowClick);

    return () => window.removeEventListener("click", windowClick);
  }, [active]);
  const handleFakeNotifications = async () => {
    const data = await generateFakeNotifications();
    // console.log(data);
  };
  const getNotifications = async () => {
    const data = await getNotificationsSummary();
    if (data === false) {
      return;
    }
    dispatch(
      updateUserInfo({ ...auth?.user, notificationSummary: data?.data })
    );
    setNotifications(
      [...data?.data?.notifications].sort(
        (a, b) => new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
      )
    );
  };
  const markAsReadHandler = async (id) => {
    setIsDisabled(true);
    let data = await markAsReadNotification(id);
    if (data === false) {
      setIsDisabled(false);
      return;
    }
    setIsDisabled(false);
    let arr = [...notifications];
    const index = arr.map((item) => item?.id).indexOf(id);
    arr[index] = data?.data;

    setNotifications(
      arr.sort(
        (a, b) => new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
      )
    );
    dispatch(
      updateUserInfo({
        ...auth?.user,
        notificationSummary: {
          countUnread: auth?.user?.notificationSummary?.countUnread - 1,
          notifications: arr.sort(
            (a, b) => new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
          ),
        },
      })
    );
  };
  const deleteHandler = async (item) => {
    setIsDisabled(true);
    let data = await deleteNotification(item?.id);
    if (data === false) {
      setIsDisabled(false);
      return;
    }
    setIsDisabled(false);
    setNotifications(
      [...notifications]
        ?.filter((filterItem) => filterItem?.id !== item?.id)
        ?.sort(
          (a, b) => new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
        )
    );
    dispatch(
      updateUserInfo({
        ...auth?.user,
        notificationSummary: {
          countUnread:
            item?.read === false
              ? auth?.user?.notificationSummary?.countUnread - 1
              : auth?.user?.notificationSummary?.countUnread,
          notifications: [...notifications]
            ?.filter((filterItem) => filterItem?.id !== item?.id)
            ?.sort(
              (a, b) =>
                new Date(b?.date) - new Date(a?.date) && a?.read - b?.read
            ),
        },
      })
    );
  };
  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth <= 840) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.addEventListener("resize", resizeHandler);
  }, []);
  useEffect(() => {
    // handleFakeNotifications();
    if (active) {
      getNotifications();
    }
  }, [active]);

  return (
    <div className={"notify "} ref={wrapper}>
      <button
        className={"notify__btn " + (active ? "active" : "")}
        onClick={toggleActive}
        type="button"
      >
        {notifyIcon}
        {auth?.user?.notificationSummary?.countUnread > 0 && (
          <div className="notify__btn-number">
            <p>
              {auth?.user?.notificationSummary?.countUnread > 99
                ? "99+"
                : auth?.user?.notificationSummary?.countUnread}
            </p>
          </div>
        )}
      </button>
      <AnimatePresence>
        {active === true && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
            className={`notify__menu  ${active ? "active" : ""}`}
          >
            <div className="notify__menu-inner">
              <div className="notify__menu-head">
                <h4>Notifications</h4>
                <div className="notify__menu-head-number">
                  <p>{auth?.user?.notificationSummary?.countUnread}</p>
                </div>
              </div>
              <div className="notify__menu-items">
                {notifications?.slice(0, 5)?.map((item, index) => {
                  return (
                    <NotificationItem
                      itemData={item}
                      key={index}
                      markAsReadHandler={() => markAsReadHandler(item?.id)}
                      deleteHandler={() => deleteHandler(item)}
                      isDisabled={isDisabled}
                      setActive={setActive}
                    />
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const NotificationItem = ({
  itemData,
  deleteHandler,
  markAsReadHandler,
  isDisabled,
  setActive,
}) => {
  const navigate = useNavigate();
  const closeBtnRef = useRef(null);
  const clickHandler = async (e) => {
    if (
      closeBtnRef?.current &&
      !closeBtnRef?.current?.contains(e.target) &&
      !isDisabled &&
      itemData?.read === false
    ) {
      await markAsReadHandler();
      if (itemData?.redirectPath && itemData?.redirectPath !== "") {
        navigate(itemData?.redirectPath);
        setActive(false);
      }
    }
  };
  return (
    <div
      className={"notify__item " + (itemData?.read === false ? "unread" : "")}
      onClick={clickHandler}
    >
      <div className="notify__item-image">
        <img src={itemData?.iconPath} alt="notification image" />
        <div className={`notify__item-image-illu`}>
          {itemData?.type?.toLowerCase() === "info" && (
            <img src={infoNotif} alt="notification badge" />
          )}
          {itemData?.type?.toLowerCase() === "gtb" && (
            <img src={gtbNotif} alt="notification badge" />
          )}
          {itemData?.type?.toLowerCase() === "rewards" && (
            <img src={rewardsNotif} alt="notification badge" />
          )}
          {itemData?.type?.toLowerCase() === "vip" && (
            <img src={vipNotif} alt="notification badge" />
          )}
          {itemData?.type?.toLowerCase() === "kick" && (
            <img src={kickNotif} alt="notification badge" />
          )}
        </div>
      </div>
      <div className="notify__item-content">
        <h6>{itemData?.content}</h6>
        <p>{moment(itemData?.date).fromNow()}</p>
      </div>
      <button
        ref={closeBtnRef}
        disabled={isDisabled}
        type="button"
        className="notify__item-close"
        onClick={deleteHandler}
      >
        {closeIcon}
      </button>
    </div>
  );
};
