import { toast } from "react-toastify";
import {
    toastErrorIcon,
    toastInfoIcon,
    toastSuccessIcon,
    toastWarnIcon,
  } from "components/base/SVG";
export const handleToast = (type, message) => {
  if (type === "success") {
    toast.success(
      <>
        <h6>Success</h6>
        <p>{message}</p>
      </>,
      {
        icon: toastSuccessIcon,
        className: "toastSuccess",
      }
    );
  } else if (type === "error") {
    toast.error(
      <>
        <h6>Error</h6>
        <p>{message}</p>
      </>,
      {
        icon: toastErrorIcon,
        className: "toastError",
      }
    );
  } else if (type === "warning") {
    toast.warning(
      <>
        <h6>Warning</h6>
        <p>{message}</p>
      </>,
      {
        icon: toastWarnIcon,
        className: "toastWarn",
      }
    );
  } else if (type === "info") {
    toast.info(
      <>
        <h6>Info</h6>
        <p>{message}</p>
      </>,
      {
        icon: toastInfoIcon,
        className: "toastInfo",
      }
    );
  } else return;
};
