import { closeIcon, navCloseIcon } from "components/base/SVG";
import Input from "components/inputs/Input";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "assets/images/logo.svg";
import { handleLogin } from "services/authService";
import { handleToast } from "utils/toasts";
import { useDispatch } from "react-redux";
import { loginUser } from "store/redux/authSlice";

export default function LoginMod({ setModal }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const loginData = await handleLogin(form?.email, form?.password);
    if (loginData === false) {
      setDisabled(false);
      handleToast("error", "Wrong email or password"); // Toast non traduit
      return;
    }
    localStorage.setItem("token", loginData?.data?.token);
    dispatch(loginUser(loginData?.data?.user));
    handleToast("success", "Logged in successfully"); // Toast non traduit
    setDisabled(false);
    setModal(null);
  };

  return (
    <div className="modal__inner">
      {/* <div className="modal__head">
        <Link
          to={"/"}
          className="modal__head-logo"
          onClick={() => {
            setModal(false);
          }}
        >
          <img src={logo} alt={t("loginMod.logoAlt")} />
        </Link>
        <button
          type="button"
          className="modal__head-close"
          onClick={() => {
            setModal(false);
          }}
        >
          {navCloseIcon}
        </button>
      </div> */}
      <div className="modal__inner-close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modal__inner-title">
        <h4>{t("loginMod.title")}</h4>
      </div>
      <form className="modalLogin" onSubmit={handleSubmit}>
        <div className="modalLogin__inputs">
          <Input
            type={"email"}
            name={"email"}
            value={form?.email}
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            label={t("loginMod.emailLabel")}
            placeholder={t("loginMod.emailPlaceholder")}
          />
          <Input
            type={"password"}
            name={"password"}
            value={form?.password}
            onChange={(e) => {
              updateForm({ password: e.target.value });
            }}
            label={t("loginMod.passwordLabel")}
            placeholder={t("loginMod.passwordPlaceholder")}
          />
        </div>

        <div
          className="modalLogin__link"
          onClick={() => setModal("forgot-password")}
        >
          {t("loginMod.forgotPassword")}
        </div>
        <button type="submit" className="button continue" disabled={disabled}>
          <span className="default"></span>
          <span className="hov"></span>
          <span className="active"></span>
          <p>{t("loginMod.continueButton")}</p>
        </button>
      </form>
    </div>
  );
}
