import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "store/store";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.css";

import languageInit from "i18n/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
languageInit.then(() => {
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
          <ToastContainer
            hideProgressBar={true}
            position="bottom-right"
            autoClose={3000}
          />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
});
